import { addOrsBetweenKeywords } from './strings';
import { cleanKeywordSearchString } from './cleanSearchTerms';
import { CoverLot, EmptyCoverLot } from '@/types/CoverLot';
import { DEFAULT_STATUS_FOR_ONLINE_SEARCH, parseQueryParams } from './queryParams';
import { ItemFacets } from '@/types/ItemFacets';
import { sanitizeHtml } from './safelyRender';
import { SearchParams } from '@/types/SaveSearch';
import { SearchSortAndDirection } from '@/types/search/enums/sortTypes';
import { shippingProviders } from '@/enums/shippingProviders';
import { translateAccents } from '@/utils/translateCharacterAccents';
import pickBy from 'lodash/pickBy';
import queryString from 'qs';

export const imagePlaceholder = 'https://p1.liveauctioneers.com/dist/images/placeholder.jpg';

export const getCategoryIdsFromPathname = (pathname: string = '') => {
    if (pathname === '/catalog/search') {
        return '0';
    }
    return pathname
        .split('/')
        .filter((x) => Number(x))
        .join(',');
};

export const getSaveSearchCategoryIdFromPathname = (pathname: string = ''): string => {
    if (pathname !== '/c/' && pathname !== '/price-guide/') {
        return '0';
    }
    const categoryId = pathname.split('/').filter((x) => Number(x));
    return categoryId.length ? categoryId[categoryId.length - 1] : '0';
};

export const getCombinationCategoryIdsFromPath = (params: any, queryParams: any) => {
    if (!params.categoryId2) {
        return '';
    }
    let ids = [params.categoryId, params.categoryId2];
    for (let key in queryParams) {
        ids.push(queryParams[key]);
    }
    ids.sort(function (a, b) {
        return a - b;
    });
    return ids.join(',');
};

// gets the last categoryid from the path
export const getFinalCategoryIdFromPathname = (pathname: string = '') => {
    if (pathname === '/catalog/search') {
        return '';
    }
    const categoryIds = pathname.split('/').filter((x) => Number(x));
    if (categoryIds.length > 0) {
        return categoryIds[categoryIds.length - 1];
    }
    return '';
};

// retrieves the last string from a path, which in some scenarios will be the lowest-level category desired
// returns category name formatted to be passed for wordpress api search
export const getCategoryNameFromPathname = (pathname: string = '') => {
    const categoryName = pathname.split('/').filter((x) => x && !Number(x));
    const cleanedUpCategoryName = categoryName.length ? categoryName[categoryName.length - 1].replace(/-/g, ' ') : '';
    return cleanedUpCategoryName;
};

export const getCategoryNameFromPathnameWithHyphens = (pathname: string = '') => {
    const categoryName = pathname.split('/').filter((x) => x && !Number(x));
    const cleanedUpCategoryName = categoryName.length ? categoryName[categoryName.length - 1] : '';
    return cleanedUpCategoryName;
};

export const getRawCategoryNameFromPathname = (pathname: string = '') => {
    const categoryName = pathname.split('/').filter((x) => x && !Number(x));
    return categoryName[categoryName.length - 1];
};

export const selectSuggestedCategoryCarouselUrl = (
    categoryId: string,
    categoryId2: number,
    categoryName: string,
    filterFacetId: number,
    location: any,
    url: string
) => {
    if (categoryId2) {
        return location.search.includes('?category_id')
            ? `${location.pathname}${location.search},${filterFacetId}`
            : `${location.pathname}?category_id=${filterFacetId}`;
    }
    return `/c/${categoryName}/${url}/${categoryId}/${filterFacetId}/`;
};

export const getIdFromPath = (path: string) => {
    if (!path) {
        return 0;
    }

    const pathString = `${path}`;
    const cleaned = pathString.replace(
        /^\/?(catalog(_gallery)?|item|auctioneer|catinfot|console|checkoutATG|checkout|terms)\/?/,
        ''
    );

    let urlFrags = cleaned.split('_')[0];

    if (urlFrags.includes('/page')) {
        urlFrags = urlFrags.split('/')[0];
    }

    const idMatches = urlFrags.match(/^(\d+)\/?$/);
    return idMatches ? Number(idMatches[1]) || 0 : 0;
};

export const getItemIdFromPath = (path: string) => {
    if (!path) {
        return 0;
    }

    const pathString = `${path}`.toLowerCase();
    const itemIdMatches = pathString.match(/item\/(\d+)/);

    return itemIdMatches !== null ? Number(itemIdMatches[1]) : 0;
};

/** getSellerIdFromPath
 * extracts and returns sellerId from matching pathname
 * @param string path
 * @return number
 */
export const getSellerIdFromPath = (path: string) => {
    if (!path) {
        return 0;
    }

    const pathString = `${path}`.toLowerCase();
    const sellerIdMatches = pathString.match(/auctioneer\/(\d+)/);

    return sellerIdMatches !== null ? Number(sellerIdMatches[1]) : 0;
};

// https://stackoverflow.com/a/1714899/148256
// Returns a string from the object
export const toQueryString = (obj: any) => {
    const arr: string[] = [];

    // Checks if object has prototypes, if not, creates a new object with default prototypes
    if (Boolean(obj) && !Boolean(obj.__proto__)) {
        obj = { ...obj };
    }

    for (const p in obj) {
        if (obj.hasOwnProperty(p)) {
            arr.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
        }
    }
    arr.sort();
    return arr.join('&');
};

export const sellerIdFromPath = (path: string) => {
    if (!path) {
        return 0;
    }
    let sellerId = Number(path);

    if (!sellerId) {
        sellerId = Number(path.split('-')[0]);
    }
    return sellerId;
};

export const logoUrl = 'https://p1.liveauctioneers.com/dist/images/logo_og.jpg';
export const signupLadySmallUrl = 'https://p1.liveauctioneers.com/dist/images/SignupLadySmall.png';
export const signupLadyPlaceholderUrl = 'https://p1.liveauctioneers.com/dist/images/SignupLadySmall.png?quality=15';
export const mobileLogo = 'https://p1.liveauctioneers.com/dist/images/logo-1024.png';
export const enableBrowserNotificationsUrl =
    'https://help.liveauctioneers.com/hc/en-gb/articles/24746619899409-How-to-enable-browser-notifications';
export const shippingHelpUrl = 'https://help.liveauctioneers.com/category/580-shipping-information';

export const urlSafeTitle = (title: string = '') => {
    let cleanedAndDecodedHtml = sanitizeHtml(title);
    cleanedAndDecodedHtml = translateAccents(cleanedAndDecodedHtml);
    const safe = cleanedAndDecodedHtml
        .trim()
        .toLowerCase() // & to and
        .replace(/&/g, 'and') // Replace , * ; : ' " to hyphen
        .replace(/[,*;:'"]+/g, '-') // Replace `- -` to single hyphen
        .replace(/-*\s+-*/g, '-') // Remove special characters
        .replace(/[^a-zA-Z0-9-_-]/g, '') // many hyphen to one
        .replace(/--+/g, '-') // Remove hyphen if it is the last character
        .replace(/-+$/, '');
    const urlEncoded = encodeURIComponent(safe).toLowerCase(); // lower case it again since the URI encoded values can be upper case
    return urlEncoded;
};

export const setQueryParams = (base: string, params: any = {}) => {
    let cleanedBase = base.replace(/\?.*/, '');
    const queryString = toQueryString(params);
    return queryString === '' ? cleanedBase : `${cleanedBase}?${queryString}`;
};

export const buildQueryUrl = (base, params?: any): string => {
    const queryString = toQueryString(params);
    return queryString === '' ? base : `${base}?${queryString}`;
};

export const getAuctionCalendarBaseUrl = () => '/catalog/search/';

export const getAuctionCalendarUrlWithParams = (params?: any) => {
    const base = getAuctionCalendarBaseUrl();
    return buildQueryUrl(base, params);
};

export const getSearchBaseUrl = () => '/search/';

/**
 * GetSearchUrlParams type
 * TODO: needs resolution with SearchParams, could just be the SearchParams type extended
 * with pagination values for a URL generation or submitted search
 * category_id and status types need to be sorted out
 */
export type GetSearchUrlParams = {
    category_id?: number;
    house_id?: string;
    itemId?: number;
    keyword?: string;
    page?: number;
    pageSize?: number | 'all';
    sort?: SearchSortAndDirection;
    status?: any;
};

export const getSearchUrlWithParams = (params: GetSearchUrlParams) => {
    const base = getSearchBaseUrl();
    return buildQueryUrl(base, params);
};

export const addSortToSearchUrl = (searchUrl: string, sort: SearchSortAndDirection): string => {
    // baseUrl is here just to give new URL a 'proper' url to parse
    const baseUrl = 'https://www.liveauctioneers.com/';
    const url = new URL(searchUrl, baseUrl);
    let queryParams = queryString.parse(url.search.substring(1));
    queryParams.sort = sort;
    return `${url.pathname}?${queryString.stringify(queryParams)}`;
};

export const getCategoryUrlWithParams = (base: string, params: any) => {
    return buildQueryUrl(base, params);
};

export const getCategoryFacetLink = (base: string, params: any) => {
    base = base === '' || base === undefined ? '/c/' : `/c/${base}/`;
    return buildQueryUrl(base, params);
};

//keywords: string could just be 'rings' or could be 'paintings/oil'
//categoryId: string. Could be '96' or could be '10,25830'(which is a subcategory)
export const getCategoryPageUrl = (keywords: string, categoryId: string, params?: any): string => {
    const url = `/c/${keywords}/${categoryId.replace(',', '/')}/`;
    return buildQueryUrl(url, params);
};

export const getSimilarItemsPageUrlWithParams = (base: string, params: any) => {
    const url = base.replace(/\/(c|search)\//, '/similar-items/');
    return buildQueryUrl(url, params);
};

export const getCombinedCategoryPageUrl = (
    firstCategory: { id: string | number; name: string },
    secondCategory: { id: string | number; name: string }
) => {
    const url = `/c/${urlSafeTitle(firstCategory.name)}/${urlSafeTitle(
        secondCategory.name
    )}/${firstCategory.id}/${secondCategory.id}/`;
    return buildQueryUrl(url);
};

const removeLastSlash = (text: string = '') => {
    if (text?.length && text[text.length - 1] === '/') {
        return text.substring(0, text.length - 1);
    }
    return text;
};

export const getACHTermsAndConditionsUrl = () => '/achtermsandconditions';
export const getAuctionResultsUrl = () => '/auction-results';
export const getAuctioneerDirectoryUrl = () => '/auctioneer/directory/';
export const getLiveAuctioneersCareersUrl = () => 'https://www.auctiontechnologygroup.com/careers/';
export const getCatalogUrl = (id: number, title: string) => `/catalog/${id}_${urlSafeTitle(title)}/`;
export const getCategoryLandingPageBaseUrl = () => '/c/';
export const getCategoryLandingPageUrl = (categoryName: string, categoryId: number) =>
    `/c/${urlSafeTitle(categoryName)}/${categoryId}/`;
export const isCategoryPage = (path: string = '') => path.includes(getCategoryLandingPageBaseUrl());
export const getCatalogTermsUrl = (id: number, title: string) => `/terms/${id}_${urlSafeTitle(title)}`;
export const getCheckoutUrl = (catalogId: number) => `/checkout/${catalogId}`;
export const getCheckoutUrlATG = (catalogId: number) => `/checkoutATG/${catalogId}`;
export const getConsignmentsUrl = () => '/consignments';
export const getCookiePolicyUrl = () => '/cookie-policy';
export const getDiscoverPageUrl = (topic: string) => removeLastSlash(`/discover/${topic}`);
export const getDisputeHelpUrl = () => 'https://help.liveauctioneers.com/article/163-how-to-handle-a-dispute';
export const getFeaturedCatalogsUrl = () => '/featured-auctions';
export const getFollowedSearches = (append?: string) => `/dashboard/followed-searches/${append ? `${append}/` : ''}`;
export const getFollowedSellersUrl = () => '/dashboard/followed-auctioneers';
export const getFollowedSellersForYouUrl = () => '/dashboard/followed-auctioneers-for-you';
export const getForYouUrl = (append?: string) => `/dashboard/for-you/${append ? `${append}/` : ''}`;
export const isForYouPage = (path: string) => path.includes('/dashboard/for-you');
export const getHomeUrl = () => '/';
export const getHowAuctionsWorkUrl = () => '/how-auctions-work';
export const getHowTimedAuctionsWorkUrl = () =>
    'https://support.liveauctioneers.com/hc/en-gb/articles/24574071591313-How-do-Timed-auctions-work';
export const getIntellectualPropertyUrl = () => '/intellectualproperty';
export const getItemUrl = (id: number, title: string) => (Boolean(id) ? `/item/${id}_${urlSafeTitle(title)}` : '');
export const isItemPage = (path: string = '') => path.includes('/item/');
export const isCatalogPage = (path: string = '') => path.toLowerCase().includes('/catalog/');
export const getLiveBiddingUrl = (catalogId: number) => `/console/${catalogId}`;
export const getAuctionVideoUrl = (catalogId: number) => `/video/${catalogId}`;
export const getConsoleImageCarouselUrl = (catalogId: number, itemId: number, imageNum: number) =>
    `/console/${catalogId}/${itemId}/images/${imageNum}`;
export const getMessagesUrl = () => '/dashboard/messages';
export const getMyAuctionsUrl = () => '/dashboard/my-auctions';
export const getMyBidsUrl = () => '/dashboard/my-bids';
export const getMyBidsItemsTabUrl = () => '/dashboard/my-bids-my-items';
export const getMyBidsMyItemsPageUrl = () => '/dashboard/my-bids-my-items';
export const getNotFoundUrl = () => '/not-found';
export const getNotificationsUrl = (section?: string) =>
    `/dashboard/emails-and-notifications${section ? `?section=${section}` : ''}`;
export const getRecommendedItemsUrl = () => '/recommended';
export const getRecentItemsUrl = () => '/recent';
export const getReviewUrl = (catalogId: number) => `/review/${catalogId}`;
export const getPreferencesUrl = () => '/dashboard/profile?tab=preferences';
export const getPriceGuideBaseUrl = () => '/price-guide/';
export const isPriceGuidePage = (path: string = '') => path.includes(getPriceGuideBaseUrl());
export const getPriceGuideUrl = (name: string, id: number) => `${getPriceGuideBaseUrl()}${urlSafeTitle(name)}/${id}/`;
export const isPriceResultsPage = (path: string = '') => path.includes('/price-result/');
export const getPriceResultsUrl = (slug: string, id?: number) =>
    Boolean(id) ? `/price-result/${slug}/?itemId=${id}` : `/price-result/${slug}/`;
export const getPrivacyUrl = () => '/privacy';
export const getProfileUrl = (provider?: number) =>
    provider ? `/dashboard/profile?provider=${provider}` : '/dashboard/profile';
export const getSavedItemsUrl = () => '/dashboard/saved-items';
export const isSearchPage = (path: string = '') => path.includes('/search/');
export const isSearchResultsPage = (path: string = '') => path.includes('/search-results/');
export const isSimilarItemsPage = (path: string = '') => path.includes('/similar-items/');
export const isSavedItemsPage = (path: string = '') => path.includes('/saved-items');
export const getSellPageUrl = () => 'https://www.liveauctioneers.com/pages/sell/';
export const getSellerUrl = (id: number, title: string) => `/auctioneer/${id}/${urlSafeTitle(title)}/`;
export const getSellerReviewsUrl = (id: number, title: string, filter: string = '') =>
    filter
        ? `/auctioneer/${id}/${urlSafeTitle(title)}/reviews?filter=${filter}`
        : `/auctioneer/${id}/${urlSafeTitle(title)}/reviews`;
export const getStoreFrontUrl = (sellerId: number) => `/seller/${sellerId}`;
export const getSupportUrl = () => 'https://help.liveauctioneers.com';
export const getTerminologyUrl = () => '/terminology';
export const getTermsAndConditionsUrl = () => '/termsandconditions';
export const getTermsAndConditionsPaymentsUrl = () => '/termsandconditionspayments';
export const getSellerTermsAndConditionsPaymentsUrl = () =>
    'https://support.liveauctioneers.com/article/92-liveauctioneers-seller-terms-conditions';
export const getFeeScheduleUrl = () =>
    'https://support.liveauctioneers.com/article/182-traditional-auction-vs-online-only-auction';
export const getWonItemsPageUrl = () => '/dashboard/won-items';
export const getTaxCertManagementUrl = () => '/dashboard/profile?tab=tax-cert-management';
export const getManageCategoryNotificationsUrl = (bidderEmail: string) =>
    `https://link.liveauctioneers.com/manage/4x3/category-management-v2?email=${bidderEmail}`;

export const getJewelryShippingInsuranceUrl = () => 'https://www.jewelersmutual.com/liveauctioneers';

export const getAuctioneerLogoUrl = (logoId: string = '', width: number = 110) => {
    return logoId && `https://images.liveauctioneers.com/houses/logos/lg/${logoId}_large.jpg?width=${width}&quality=80`;
};

export const getAuctioneerLogoUrlPlaceholder = (auctioneer: string | null | undefined = '', width: number = 110) => {
    return `${getAuctioneerLogoUrl(auctioneer, width)}&quality=15`;
};

export const getAllUpcomingAuctionsUrl = () => getAuctionCalendarUrlWithParams();

export type GetImageUrlParams = {
    blur?: any;
    catalogId?: number;
    crop?: any;
    height?: number;
    imageNumber?: number;
    imageVersion?: number;
    itemId?: number;
    quality?: any;
    sellerId?: number;
    width?: number;
};

export const getImageUrl = ({
    blur,
    catalogId,
    crop,
    height,
    imageNumber = 1,
    imageVersion = 1,
    itemId,
    quality,
    sellerId,
    width,
}: GetImageUrlParams) => {
    if (!sellerId || !catalogId || !itemId || !imageNumber) {
        return '';
    }

    return addImageParams({
        blur,
        crop,
        height,
        quality,
        url: `https://p1.liveauctioneers.com/${sellerId}/${catalogId}/${itemId}_${imageNumber}_x.jpg`,
        version: imageVersion,
        width,
    });
};

export type AddImageParamTypes = {
    blur?: any;
    crop?: any;
    height?: number;
    quality?: number;
    sharpen?: boolean;
    url?: string;
    version?: number;
    width?: number;
};

export const addImageParams = ({
    blur,
    crop,
    height,
    quality = 70,
    sharpen,
    url = '',
    version,
    width,
}: AddImageParamTypes) => {
    const query = pickBy({
        blur,
        crop,
        height,
        quality,
        sharpen,
        version,
        width,
    });

    if (!url) {
        return '';
    }

    const [path = '', queryParams = ''] = url.split('?');
    const params = (Boolean(queryParams) && parseQueryParams(`?${queryParams}`)) || {};
    return `${path.replace('http://', 'https://')}?${toQueryString({
        ...params,
        ...query,
    })}`.trim();
};

export const getPlaceholderItemImageUrl = (
    sellerId: number,
    catalogId: number,
    itemId: number,
    imageNumber: number,
    imageVersion: number = 1
) =>
    getImageUrl({
        catalogId,
        height: 612,
        imageNumber,
        imageVersion,
        itemId,
        quality: 10,
        sellerId,
    });

export const getMediumItemImageUrl = (
    sellerId: number,
    catalogId: number,
    itemId: number,
    imageNumber: number,
    imageVersion: number = 1,
    quality: number = 70
) =>
    getImageUrl({
        catalogId,
        height: 512,
        imageNumber,
        imageVersion,
        itemId,
        quality,
        sellerId,
    });

export const getCuratorImageUrl = (
    baseUrl: string,
    curatorId: number,
    curatorImageFileName: string,
    size: number = 280
) =>
    `${baseUrl}/${curatorId}/${encodeURIComponent(curatorImageFileName)}?width=${size}&aspect_ratio=100:101&quality=70`;

export const getItemCardImageUrl = (
    sellerId: number,
    catalogId: number,
    itemId: number,
    imageNumber: number,
    imageVersion: number
) =>
    getImageUrl({
        catalogId,
        height: 280,
        imageNumber,
        imageVersion,
        itemId,
        sellerId,
    });

export const getDeskSupportLink = () => 'https://help.liveauctioneers.com/';

export const getAdQuestionUrl = () => 'https://www.surveymonkey.com/r/KFCCVSX';

export const getSearchPageMostPopular = () =>
    getSearchUrlWithParams({ page: 1, sort: SearchSortAndDirection.Popularity });

const categoryLandingIds = {
    art: 1,
    asian: 158,
    collectibles: 2,
    fashion: 3,
    furniture: 4,
    'home-and-decor': 5,
    jewelry: 6,
};

export const getCategoryLandingPageUrlFromCategoryName = (category: string) => {
    const categoryId = categoryLandingIds[category];
    return categoryId
        ? `${getCategoryLandingPageBaseUrl()}${category}/${categoryId}/`
        : getCategoryLandingPageBaseUrl();
};

export const separateURL = (url: string) => {
    const match = url.match(/^(https?:)\/\/(([^:/?#]*)(?::([0-9]+))?)([/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/);
    if (match === null) {
        return {
            host: '',
            path: '',
        };
    }
    return {
        host: match[2],
        path: match[5],
    };
};

export const getSearchAuctioneerUrl = (sellerId: number) =>
    getSearchUrlWithParams({
        house_id: `${sellerId}`,
        page: 1,
        sort: SearchSortAndDirection.Popularity,
    });

export const getSearchAuctioneerSoldUrl = (sellerId: number) =>
    getSearchUrlWithParams({
        house_id: `${sellerId}`,
        page: 1,
        status: 'archive',
    });

/**
 * getBroadTitleSearchParams
 * returns provided title string separated by OR strings in SearchParams object
 * keyword node to yield a broader search result
 * if sellerId is provided it is added as house_id node, converted to string
 * @param title string
 * @param sellerId number
 * @returns SearchParams
 */
export const getBroadTitleSearchParams = (title: string = '', sellerId: number = 0): SearchParams => {
    const keyword = title
        .split(/\s+/g)
        .map((word) => `${word}`)
        .join(' OR ')
        .replace(/"/g, '\\"');
    const params: SearchParams = {
        keyword,
        status: DEFAULT_STATUS_FOR_ONLINE_SEARCH,
    };
    if (sellerId) {
        params.house_id = sellerId.toString();
    }
    return params;
};

/**
 * getOnTheHuntItemSearchParams - creates SearchParams object with given
 * facets and title. Interposes OR between words in provided title for
 * keyword created searches over 4 words long - restored in WWF-185
 * @param facets
 * @param title
 * @returns SearchParams
 */
export const getOnTheHuntItemSearchParams = (facets: ItemFacets, title: string = '') => {
    const params: SearchParams = {};
    const MAX_WORDS = 4;

    if (facets?.creators?.length) {
        params.category_id = facets.creators[0].l1CategoryId?.toString();
    } else {
        params.keyword = addOrsBetweenKeywords(cleanKeywordSearchString(title), MAX_WORDS);
    }
    return params;
};

export const getSimilarItemsUrl = (title: string = '', sellerId: number = 0) => {
    const params = getBroadTitleSearchParams(title, sellerId);
    // update status for URL link
    params.status = ['online', 'live', 'upcoming'];
    return getSearchUrlWithParams(params);
};

export const getCoverLots = (coverLots: CoverLot[], height?: number) => {
    const [first, second, third] = coverLots.length ? coverLots : [EmptyCoverLot, EmptyCoverLot, EmptyCoverLot];
    const primaryImage = first?.url
        ? addImageParams({
              height: height || 260,
              url: first?.url,
              version: first?.imageVersion,
          })
        : imagePlaceholder;
    const primaryImageHighDpi = first?.url
        ? addImageParams({
              height: height * 2 || 520,
              url: first?.url,
              version: first?.imageVersion,
          })
        : imagePlaceholder;
    const primaryItemUrl = getItemUrl(first?.itemId, first?.title);

    const secondaryImage = second?.url
        ? addImageParams({
              height: height || (coverLots?.length === 2 ? 260 : 130),
              url: second?.url,
              version: second?.imageVersion,
          })
        : imagePlaceholder;
    const secondaryImageHighDpi = second?.url
        ? addImageParams({
              height: height * 2 || (coverLots?.length === 2 ? 520 : 260),
              url: second?.url,
              version: second?.imageVersion,
          })
        : imagePlaceholder;
    const secondaryItemUrl = getItemUrl(second?.itemId, second?.title);

    const tertiaryImage = third?.url
        ? addImageParams({
              height: height || 130,
              url: third?.url,
              version: third?.imageVersion,
          })
        : imagePlaceholder;
    const tertiaryImageHighDpi = third?.url
        ? addImageParams({
              height: height * 2 || 260,
              url: third?.url,
              version: third?.imageVersion,
          })
        : imagePlaceholder;
    const tertiaryItemUrl = getItemUrl(third?.itemId, third?.title);

    return {
        primaryImage,
        primaryImageHighDpi,
        primaryItemId: first?.itemId,
        primaryItemTitle: first?.title,
        primaryItemUrl,
        secondaryImage,
        secondaryImageHighDpi,
        secondaryItemId: second?.itemId,
        secondaryItemTitle: second?.title,
        secondaryItemUrl,
        tertiaryImage,
        tertiaryImageHighDpi,
        tertiaryItemId: third?.itemId,
        tertiaryItemTitle: third?.title,
        tertiaryItemUrl,
    };
};

const getWebviewsUrl = (path: string, deployment: string) => {
    let domain = `https://webviews${deployment === 'prod' ? '' : `-${deployment}`}.liveauctioneers.com`;
    // domain = 'https://localdev.liveauctioneers.com:3002';
    return `${domain}/${path}`;
};

export const getPayrixAddCreditCardUrl = (
    deployment: string,
    address: any,
    authToken?: string,
    showSignUpFlow?: boolean
): string => {
    let url = getWebviewsUrl('addcreditcard/3', deployment);
    let queryParam = '';

    if (address) {
        queryParam = `address1=${encodeURIComponent(address.address1)}&address2=${encodeURIComponent(
            address.address2
        )}&state=${encodeURIComponent(address.state)}&countryCode=${encodeURIComponent(
            address.countryCode
        )}&city=${encodeURIComponent(address.city)}&postalCode=${encodeURIComponent(address.postalCode)}`;
    }
    if (Boolean(authToken)) {
        queryParam = `${queryParam}${queryParam ? '&' : ''}token=${authToken}`;
    }
    if (showSignUpFlow) {
        queryParam = `${queryParam}${queryParam ? '&' : ''}showSignUpFlow=1`;
    }
    if (queryParam) {
        url = `${url}?${queryParam}`;
    }
    return url;
};

export const getPaysafeAddCreditCardUrl = (deployment: string, address: any, token?: string) => {
    let url = getWebviewsUrl('addcreditcard/4', deployment);
    let queryParam;
    if (address) {
        queryParam = `address1=${encodeURIComponent(address.address1)}&address2=${encodeURIComponent(
            address.address2
        )}&state=${encodeURIComponent(address.state)}&countryCode=${encodeURIComponent(
            address.countryCode
        )}&city=${encodeURIComponent(address.city)}&postalCode=${encodeURIComponent(address.postalCode)}`;
    }
    if (token) {
        queryParam = `${queryParam ? queryParam : ''}${queryParam ? '&' : ''}token=${encodeURIComponent(token)}`;
    }
    if (queryParam) {
        url = `${url}?${queryParam}`;
    }
    return url;
};

export const getEkataTermsUrl = () => 'https://ekata.com/privacy/';

export const getPayrixTermsUrl = () => 'https://portal.payrix.com/terms';

export const getTaxCertCaptureUrl = (
    deployment: string,
    token: string,
    shipZone: string,
    userData: object,
    bidderId: number
) => {
    // exit with a falsy value if we are missing data.
    if (!token || !shipZone) {
        return '';
    }
    let url = getWebviewsUrl('taxcertcapture', deployment);
    let queryParam = '';
    if (token && shipZone) {
        queryParam = `token=${token}&shipZone=${shipZone}`;
    }
    if (token && shipZone) {
        queryParam = `${queryParam}&bidderId=${bidderId}`;
    }
    if (userData) {
        queryParam = `${queryParam}&userData=${encodeURIComponent(JSON.stringify(userData))}`;
    }
    if (queryParam) {
        url = `${url}?${queryParam}`;
    }

    return url;
};

export const getCertCapturePageUrl = (shipZone: string) => {
    const url = '/certcapture';
    let queryParam = '';
    if (shipZone) {
        queryParam = `?shipZone=${shipZone}`;
    }
    return `${url}${queryParam}`;
};

export type AuctionsNearMeGeoAreaParams = {
    geoAreaId: number;
    geoAreaName: string;
};

export const getAuctionsNearMePageUrl = (params?: AuctionsNearMeGeoAreaParams) => {
    let url = '/find-auctions-near-me/';
    if (params) {
        url += `${params.geoAreaId}/${params.geoAreaName.replace(/\s+/g, '-').toLowerCase()}/`;
    }
    return url;
};

export const getShippingPartnerPolicyUrl = (partnerId: keyof typeof shippingProviders): string => {
    const baseUrl = `/shipping-policies/${partnerId}`;
    switch (Number(partnerId)) {
        case 3:
            return `${baseUrl}/thepackengers`;
        case 4:
            return `${baseUrl}/national-logistic-services`;
        default:
            return '';
    }
};

type GoogleMapAddress = {
    city: string;
    country: string;
    region: string;
};

export const getGoogleMapUrlFromAddress = ({ city, country, region }: GoogleMapAddress) => {
    const location = [city, region, country]
        // remove duplicate whitespaces then trim off extra spaces from the ends before we encode
        .join(' ')
        .replace(/\s+/g, ' ')
        .trim();
    const encodedLocation = encodeURIComponent(location);
    return `https://www.google.com/maps/place/${encodedLocation}`;
};

/**
 * Returns a string url from a location object
 * @param location Location<unknown> object
 * @param baseUrl string
 * @returns url string of the location + baseUrl
 */
export const buildUrlFromLocation = (location, baseUrl?: string) => {
    const url = `${baseUrl || ''}${location?.pathname || ''}${location?.search || ''}${location?.hash || ''}`;
    return url === '' ? undefined : url;
};

export const removeTrailingSlash = (url: string) => (url?.[url?.length - 1] === '/' ? url.slice(0, -1) : url);
export const removeScheme = (url: string) => url?.replace('https://', '').replace('http://', '') || url;

export const getAreUrlsEqual = (firstUrl: string, secondUrl: string) => {
    if (typeof firstUrl !== 'string' || typeof secondUrl !== 'string') {
        return false;
    }
    const firstUrlWithoutQuery = firstUrl?.split('?')?.[0];
    const secondUrlWithoutQuery = secondUrl?.split('?')?.[0];

    const firstWithoutScheme = removeScheme(firstUrlWithoutQuery);
    const secondWithoutScheme = removeScheme(secondUrlWithoutQuery);

    return removeTrailingSlash(firstWithoutScheme) === removeTrailingSlash(secondWithoutScheme);
};

export type PathLocale = {
    /** parsed locale, if available */
    locale: string;
    /** parsed locale with / prefix */
    localePath: string;
    /** remaining path with locale prefix removed */
    path: string;
};

/**
 * parsePathLocale - parses locale string out of incoming path string and returns object
 * with parts returned in locale and path
 * if no locale is parsed, locale and localePath are blank string, path is what was sent
 * @param path string
 * @returns PathLocale
 */
export const parsePathLocale = (path: string): PathLocale => {
    const match = path.match(/^\/(\w{2}-\w{2})\W*/);
    if (match?.[1]) {
        const localePath = `/${match[1]}`;
        return {
            locale: match[1],
            localePath,
            path: path.replace(localePath, ''),
        };
    }
    return { locale: '', localePath: '', path };
};
